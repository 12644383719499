import {
  toMappingItem,
  type Mapping,
  type MappingItem,
} from '@pn/core/domain/data';
import { isNil } from 'lodash-es';
import type { ParquetMappingItem, ParquetSourceType } from './types';

/**
 * @link https://github.com/ankane/polars-ruby?tab=readme-ov-file#types
 */
export function parquetSourceTypeToDomainType(
  sourceType: ParquetSourceType
): MappingItem['domainType'] {
  switch (sourceType) {
    case 'Boolean':
      return 'boolean';
    case 'Float32':
    case 'Float64':
    case 'Int8':
    case 'Int16':
    case 'Int32':
    case 'Int64':
      return 'number';
    case 'String':
      return 'string';
    case 'Date':
      return 'DateString';
    case 'WKTString': // special type
      return 'WKT';
    default:
      throw new Error(`Invalid parquet source type: ${sourceType}`);
  }
}

// currently not used; will replace elasticToDomainMapping in the future
export const parquetToDomainMapping = (
  parquetMapping: Record<string, ParquetMappingItem>
): Mapping => {
  return Object.entries(parquetMapping).reduce<Mapping>(
    (acc, [field, item]) => {
      const domainType =
        item.domainType ?? parquetSourceTypeToDomainType(item.sourceType);

      acc[field] = toMappingItem({
        field,
        ...item,
        domainType,
        domainTypeAttributes:
          domainType === 'DateString' && isNil(item.domainTypeAttributes)
            ? { format: 'yyyy-MM-dd' }
            : item.domainTypeAttributes,
      });

      return acc;
    },
    {}
  );
};
