import type { PostgresMappingItem } from '@pn/services/api/data/types';

const initialColumns: Record<string, PostgresMappingItem> = {
  wellbore_uwi: {
    field: 'wellbore_uwi',
    label: 'UWI',
    isShownByDefault: true,
    width: 175,
    sourceType: 'int',
  },
  licensee: {
    field: 'licensee',
    label: 'Licensee',
    width: 175,
    isShownByDefault: true,
    sourceType: 'text',
  },
};

const finalColumns: Record<string, PostgresMappingItem> = {
  formation: {
    field: 'formation',
    label: 'Formation',
    domainType: 'string',
    isShownByDefault: true,
    sourceType: 'text',
  },
  field_name: {
    field: 'field_name',
    label: 'Field',
    domainType: 'string',
    isShownByDefault: true,
    sourceType: 'text',
  },
  type: {
    field: 'type',
    domainType: 'string',
    label: 'Well Type',
    isShownByDefault: true,
    width: 200,
    sourceType: 'text',
  },
  on_prod_date: {
    field: 'on_prod_date',
    domainType: 'DateString',
    label: 'On Prod',
    isShownByDefault: true,
    width: 100,
    sourceType: 'date',
  },
};

const liquidsColumns: Record<string, PostgresMappingItem> = {
  ip3_liquid_bbl_per_day: {
    field: 'ip3_liquid_bbl_per_day',
    label: 'IP3',
    sourceType: 'float',
    isShownByDefault: true,
    domainType: 'number',
  },
  ip12_liquid_bbl_per_day: {
    field: 'ip12_liquid_bbl_per_day',
    label: 'IP12',
    sourceType: 'float',
    isShownByDefault: true,
    domainType: 'number',
  },
  ip3_oil_bbl_per_day: {
    field: 'ip3_oil_bbl_per_day',
    label: 'IP3',
    sourceType: 'float',
    isShownByDefault: true,
    domainType: 'number',
  },
  ip12_oil_bbl_per_day: {
    field: 'ip12_oil_bbl_per_day',
    label: 'IP12',
    sourceType: 'float',
    isShownByDefault: true,
    domainType: 'number',
  },
  ip3_condensate_bbl_per_day: {
    field: 'ip3_condensate_bbl_per_day',
    label: 'IP3',
    sourceType: 'float',
    isShownByDefault: true,
    domainType: 'number',
  },
  ip12_condensate_bbl_per_day: {
    field: 'ip12_condensate_bbl_per_day',
    label: 'IP12',
    sourceType: 'float',
    isShownByDefault: true,
    domainType: 'number',
  },
};

const gasColumns: Record<string, PostgresMappingItem> = {
  ip3_gas_mcf_per_day: {
    field: 'ip3_gas_mcf_per_day',
    label: 'IP3',
    sourceType: 'float',
    isShownByDefault: true,
    domainType: 'number',
  },
  ip12_gas_mcf_per_day: {
    field: 'ip12_gas_mcf_per_day',
    label: 'IP12',
    sourceType: 'float',
    isShownByDefault: true,
    domainType: 'number',
  },
};

export const liquidWellsByInitialProduction: Record<
  string,
  PostgresMappingItem
> = {
  ...initialColumns,
  ...liquidsColumns,
  ...gasColumns,
  ...finalColumns,
};

export const gasWellsByInitialProduction: Record<string, PostgresMappingItem> =
  {
    ...initialColumns,
    ...gasColumns,
    ...liquidsColumns,
    ...finalColumns,
  };
