import {
  getDefaultFieldsFromMapping,
  type Mapping,
} from '@pn/core/domain/data';
import {
  tableActions,
  useTableStorage,
  useWorkspaceStorage,
} from '@pn/core/storage';
import { difference, isEmpty, isNil } from 'lodash-es';
import React from 'react';

const dataTypesChecked = new Set<string>();

/**
 * When columns are removed or renamed, this effect will clear the fields from
 * local storage and reset them to default.
 * We only need to run this check once per data type.
 */
export function useForceResetTableFields() {
  const { workspaceItemSelected } = useWorkspaceStorage();
  const { tableFields } = useTableStorage();

  React.useEffect(() => {
    if (isNil(workspaceItemSelected)) return;

    const { dataType, mapping } = workspaceItemSelected;
    if (dataTypesChecked.has(dataType) || isEmpty(mapping)) return;

    const fields = tableFields[dataType] ?? [];

    if (isEmpty(fields) || !isEmpty(computeDiff(fields, mapping))) {
      tableActions().updateTableFields(
        dataType,
        getDefaultFieldsFromMapping(mapping)
      );
      console.log('%cTable fields force-reset', 'color: #E91E63');
    }

    dataTypesChecked.add(dataType);
  }, [tableFields, workspaceItemSelected]);
}

function computeDiff(fields: string[], mapping: Mapping): string[] {
  return difference(fields, Object.keys(mapping));
}
