import type { NoDataSource } from '@pn/core/domain/workspace';
import type { IDataProvider } from '@pn/core/providers/data/ports';

export const noDataProvider: IDataProvider<NoDataSource> = () => ({
  getDataMapping: async () => ({}),
  getDataByQuery: async () => ({
    data: [],
    totalCount: 0,
    page: 0,
    retrievalTime: -1,
  }),
  streamDataByQuery: async () => {},
});
