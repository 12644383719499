import type { DataItemId } from '@pn/core/domain/data';
import type { GeoShape } from '@pn/core/domain/geography';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { uniq } from 'lodash-es';

export enum MapMode {
  Standard = 'standard',
  Light = 'light',
  Dark = 'dark',
  Satellite = 'satellite',
}

export function isMapMode(arg: unknown): arg is MapMode {
  return Object.values(MapMode).includes(arg as MapMode);
}

export type MapFeature = {
  layerId: string;
  itemId: WorkspaceItem['id'];
  dataType: string;
  internal_id: DataItemId;
  geoShape: GeoShape;
};

export function getMapFeatureIds(features: MapFeature[]): DataItemId[] {
  return uniq(features.map(({ internal_id }) => internal_id));
}
