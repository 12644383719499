import type { PostgresMappingItem } from '@pn/services/api/data/types';

export const wellLicenseTransfersMapping: Record<string, PostgresMappingItem> =
  {
    id: {
      field: 'id',
      label: 'ID',
      width: 175,
      sourceType: 'int',
    },
    transfer_date: {
      field: 'transfer_date',
      domainType: 'DateString',
      label: 'Transfer Date',
      isShownByDefault: true,
      width: 125,
      sourceType: 'date',
    },
    wellbore_uwi_list: {
      field: 'wellbore_uwi_list',
      label: 'UWIs',
      isNotRenderable: true,
      isShownByDefault: true,
      sourceType: 'json',
    },
    from_company: {
      field: 'from_company',
      label: 'From',
      isShownByDefault: true,
      sourceType: 'text',
    },
    to_company: {
      field: 'to_company',
      label: 'To',
      isShownByDefault: true,
      sourceType: 'text',
    },
    province: {
      field: 'province',
      label: 'Province',
      isShownByDefault: true,
      sourceType: 'text',
      width: 60,
    },
    wellbore_count: {
      field: 'wellbore_count',
      label: 'Wellbores',
      isShownByDefault: true,
      sourceType: 'int',
      domainType: 'number',
    },
    license_count: {
      field: 'license_count',
      label: 'Licence Count',
      isShownByDefault: true,
      sourceType: 'int',
      domainType: 'number',
    },
    oil_vol_per_day: {
      field: 'oil_vol_per_day',
      label: 'Oil',
      isShownByDefault: true,
      width: 175,
      sourceType: 'float',
      domainType: 'SIUnit',
      domainTypeAttributes: { symbol: 'bbl/d' },
    },
    gas_vol_per_day: {
      field: 'gas_vol_per_day',
      label: 'Gas',
      isShownByDefault: true,
      width: 75,
      sourceType: 'float',
      domainType: 'SIUnit',
      domainTypeAttributes: { symbol: 'boe/d' },
    },
    condensate_bbl_per_day: {
      field: 'condensate_bbl_per_day',
      label: 'Condensate',
      isShownByDefault: true,
      width: 175,
      sourceType: 'float',
      domainType: 'SIUnit',
      domainTypeAttributes: { symbol: 'bbl/d' },
    },
    ngl_bbl_per_day: {
      field: 'ngl_bbl_per_day',
      label: 'NGL',
      isShownByDefault: true,
      width: 175,
      sourceType: 'float',
      domainType: 'SIUnit',
      domainTypeAttributes: { symbol: 'bbl/d' },
    },
    total_boe_production: {
      field: 'total_boe_production',
      label: 'Total Production',
      isShownByDefault: true,
      sourceType: 'float',
      domainType: 'SIUnit',
      domainTypeAttributes: { symbol: 'boe/d' },
    },
    prod_month_considered: {
      field: 'prod_month_considered',
      label: 'Prod Month Considered',
      width: 250,
      isNotSortable: true,
      isShownByDefault: true,
      sourceType: 'timestamp',
    },
  };
