import { dumpElasticMapping } from '@pn/services/api/data/elastic';
import type { ElasticMappingItem } from '@pn/services/api/data/types';
import dispositionsElasticMapping from './dispositions.jsonc';
import dispositionsHistoricalElasticMapping from './dispositions_historical.jsonc';
import facilitiesElasticMapping from './facilities.jsonc';
import gridsElasticMapping from './grids.jsonc';
import landPostingsElasticMapping from './land_postings.jsonc';
import mineralRightsElasticMapping from './mineral_rights.jsonc';
import openMineralRightsElasticMapping from './open_mineral_rights.jsonc';
import pipelinesElasticMapping from './pipelines.jsonc';
import poolsElasticMapping from './pools.jsonc';
import stackWellsElasticMapping from './stackdx/stack_wells.jsonc';
import stackWellsUsaElasticMapping from './stackdx/stack_wells_usa.jsonc';
import unitsElasticMapping from './units.jsonc';
import wellsElasticMapping from './wells.jsonc';
import wellsUsaElasticMapping from './wells_usa.jsonc';

export const elasticMappings: Record<
  string,
  Record<string, ElasticMappingItem>
> = {
  wells: wellsElasticMapping,
  wells_usa: wellsUsaElasticMapping,
  pipelines: pipelinesElasticMapping,
  facilities: facilitiesElasticMapping,
  mineral_rights: mineralRightsElasticMapping,
  open_mineral_rights: openMineralRightsElasticMapping,
  land_postings: landPostingsElasticMapping,
  units: unitsElasticMapping,
  pools: poolsElasticMapping,
  dispositions: dispositionsElasticMapping,
  dispositions_historical: dispositionsHistoricalElasticMapping,
  grids: gridsElasticMapping,
};

export const elasticStackMappings: Record<
  string,
  Record<string, ElasticMappingItem>
> = {
  wells: stackWellsElasticMapping,
  wells_usa: stackWellsUsaElasticMapping,
};

dumpElasticMapping({
  rawMapping: elasticMappings.wells,
  dumpRaw: false,
  dumpElasticTemplate: false,
});
