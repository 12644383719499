import type { SearchResult } from '@pn/core/domain/search';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export type State = {
  isFetching: boolean;
  isError: boolean;
  searchResults: SearchResult[];
};

const initialState: State = {
  isFetching: false,
  isError: false,
  searchResults: [],
};

export const createSearchSlice = (sliceName: string) =>
  createSlice({
    name: sliceName,
    initialState,
    reducers: {
      request: (state) => {
        state.isFetching = true;
        state.isError = false;
        state.searchResults = [];
      },
      receive: (state, { payload: results }: PayloadAction<SearchResult[]>) => {
        state.isFetching = false;
        state.isError = false;
        state.searchResults = results;
      },
      error: (state) => {
        state.isFetching = false;
        state.isError = true;
        state.searchResults = [];
      },
      reset: (state) => {
        state.isFetching = false;
        state.isError = false;
        state.searchResults = [];
      },
    },
  });

export const searchSlice = createSearchSlice('search');
