import type { PostgresMappingItem } from '@pn/services/api/data/types';

const wellLicenceDateField: PostgresMappingItem = {
  field: 'license_date',
  sourceType: 'timestamp',
  domainType: 'DateString',
  label: 'Licence Date',
  width: 110,
  isShownByDefault: true,
};

const wellLicenceFields: Record<string, PostgresMappingItem> = {
  id: {
    field: 'id',
    label: 'Licence Number',
    width: 100,
    sourceType: 'int',
    isShownByDefault: true,
  },
  licensee: {
    field: 'licensee',
    label: 'Licensee',
    isShownByDefault: true,
    sourceType: 'text',
  },
  formations: {
    field: 'formations',
    label: 'Formations',
    isShownByDefault: true,
    sourceType: 'text',
  },
  field_name: {
    field: 'field_name',
    label: 'Field',
    isShownByDefault: true,
    sourceType: 'text',
  },
  surface_uwi: {
    field: 'surface_uwi',
    label: 'Surface Location',
    isShownByDefault: true,
    width: 140,
    sourceType: 'text',
  },
  province: {
    field: 'province',
    label: 'Province',
    sourceType: 'text',
    isShownByDefault: true,
    width: 60,
  },
  uwis: {
    field: 'uwis',
    label: 'UWIs',
    isShownByDefault: true,
    width: 200,
    sourceType: 'text',
  },
};

export const wellLicensesMapping: Record<string, PostgresMappingItem> = {
  license_date: wellLicenceDateField,
  ...wellLicenceFields,
};

export const wellSpudsMapping: Record<string, PostgresMappingItem> = {
  last_spud_date: {
    field: 'last_spud_date',
    sourceType: 'timestamp',
    domainType: 'DateString',
    label: 'Spud Date',
    width: 110,
    isShownByDefault: true,
  },
  license_date: wellLicenceDateField,
  ...wellLicenceFields,
};

export const wellRigReleasesMapping: Record<string, PostgresMappingItem> = {
  rig_rlse_date: {
    field: 'rig_rlse_date',
    sourceType: 'timestamp',
    domainType: 'DateString',
    label: 'Rig Release Date',
    width: 110,
    isShownByDefault: true,
  },
  license_date: wellLicenceDateField,
  ...wellLicenceFields,
};

export const wellOnProdsMapping: Record<string, PostgresMappingItem> = {
  on_prod_date: {
    field: 'on_prod_date',
    sourceType: 'timestamp',
    domainType: 'DateString',
    label: 'On Prod Date',
    width: 110,
    isShownByDefault: true,
  },
  license_date: wellLicenceDateField,
  ...wellLicenceFields,
};
