import { isPNDataType } from '@pn/core/domain/data';
import {
  createWorkspaceItem,
  getItemColor,
  isDynamicWorkspaceItem,
} from '@pn/core/domain/workspace';
import {
  useCurrentUserStorage,
  useWorkspaceStorage,
  workspaceActions,
} from '@pn/core/storage';
import { findOrThrow } from '@pn/core/utils/logic';
import { muiColorPalette } from '@pn/services/color/colorPalettes';

export function useSqlCommand() {
  const { user } = useCurrentUserStorage();
  const { allWorkspaceItems } = useWorkspaceStorage();

  return async (dataType: string, ...args: string[]): Promise<string> => {
    if (dataType === '') return 'Error: No data type provided.';
    if (!isPNDataType(dataType)) return `Invalid data type: ${dataType}`;

    const sql = args.join(' ');
    // const sql =
    //   'SELECT * FROM sql_table ORDER BY recent_oil DESC NULLS LAST LIMIT 5';
    console.log('> sql', sql);

    const sourceItem = findOrThrow(
      allWorkspaceItems,
      (item) => item.id === dataType
    );

    const temporaryDynamicItems = allWorkspaceItems.filter(
      (item) => item.isTemporary && isDynamicWorkspaceItem(item)
    );

    const newItem = createWorkspaceItem(
      {
        source: 'item',
        sourceItem,
        name: 'SQL-driven layer',
        extraStyle: {
          color: muiColorPalette.getFilterColor(
            temporaryDynamicItems.map((item) => getItemColor(item) as string)
          ),
        },
        queryOptions: { sql },
      },
      user
    );

    workspaceActions().add(newItem);
    workspaceActions().addToWorkspace(newItem.id);
    workspaceActions().select(newItem.id);

    return `SQL-driven layer added to workspace.`;
  };
}
