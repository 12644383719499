import type { Mapping } from '@pn/core/domain/data';
import type { ISODateTimeString } from '@pn/core/domain/types';
import { isNil, truncate } from 'lodash-es';

export type TableTemplate = {
  id: string;
  name: string;
  createdAt: ISODateTimeString;
  sourceLayerId: string;
  fields: string[];
  isGlobal: boolean;
};

export function generateTableTemplateName(params: {
  fields: string[];
  mapping: Mapping;
}): string {
  const { fields, mapping } = params;

  const foldersWithCounts = Object.entries(mapping).reduce<
    Record<string, number>
  >((acc, [field, m]) => {
    if (!fields.includes(field)) return acc;
    if (isNil(m?.ui.folder)) return acc;

    if (isNil(acc[m.ui.folder])) acc[m.ui.folder] = 0;
    acc[m.ui.folder] += 1;

    return acc;
  }, {});

  return Object.entries(foldersWithCounts)
    .sort((a, b) => b[1] - a[1])
    .map(
      ([folder, count]) =>
        `${folder
          .split(' ')
          .map((word, index) =>
            index < folder.split(' ').length - 1
              ? truncate(word, { length: 1, omission: '' })
              : word
          )
          .join(' ')} (${count})`
    )
    .join(', ');
}
