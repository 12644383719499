import type { DataItemId } from '@pn/core/domain/data';
import { UnitSystem } from '@pn/core/domain/types';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { getExtraVisualizedFields } from '@pn/core/domain/workspace';
import { isNil, isString, pick, uniq } from 'lodash-es';

export type Query = {
  id: WorkspaceItem['id'];
  dataType: string;
  page: number;
  fields: string[];
  sorts: Sort[];
  filters: Filter[];
  filtersLinkOperator: LinkOperator;
  checkedIds: DataItemId[];
  multiSelectionReason:
    | DataMultiSelectionReason
    | DataSelectionReason
    | undefined;
  requestedIds: DataItemId[];
  sql: string | undefined; // applied before the rest of the query if set
  ignoreLimit?: boolean;
};

export function buildQuery(
  params: Pick<Query, 'id' | 'dataType'> & Partial<Query>
): Query {
  return {
    page: 0,
    fields: [],
    sorts: [],
    filters: [],
    filtersLinkOperator: 'and',
    checkedIds: [],
    multiSelectionReason: undefined,
    requestedIds: [],
    sql: undefined,
    ignoreLimit: false,
    ...params,
  };
}

const scrollableQueryKeys = [
  'dataType',
  'page',
  'fields',
  'sorts',
  'filters',
  'filtersLinkOperator',
  'requestedIds',
  'sql',
] as const;
const streamableQueryKeys = [
  'dataType',
  'fields',
  'sorts',
  'filters',
  'filtersLinkOperator',
  'requestedIds',
  'sql',
  'ignoreLimit',
] as const;

export type ScrollableQuery = Pick<
  Query,
  (typeof scrollableQueryKeys)[number]
> & { pageSize: number };
export type StreamableQuery = Pick<Query, (typeof streamableQueryKeys)[number]>;

export const getScrollableQuery = (
  query: Query,
  pageSize: number
): ScrollableQuery => {
  const draftQuery: ScrollableQuery = {
    ...pick(query, scrollableQueryKeys),
    pageSize,
  };

  draftQuery.fields = [];
  draftQuery.filters = normalizeFilters(query.filters);

  return draftQuery;
};

export const getExportableQuery = (
  query: Query,
  fields: string[]
): StreamableQuery => {
  const draftQuery: StreamableQuery = pick(query, streamableQueryKeys);

  draftQuery.fields = fields;
  draftQuery.filters = normalizeFilters(query.filters);
  draftQuery.ignoreLimit = true;

  return draftQuery;
};

export const getVisualizableQuery = (item: WorkspaceItem): StreamableQuery => {
  const draftQuery: StreamableQuery = pick(item.query, streamableQueryKeys);

  draftQuery.fields = getMapDataFields(item);
  draftQuery.filters = normalizeFilters(item.query.filters);

  return draftQuery;
};

export type Sort = {
  field: string;
  direction: 'asc' | 'desc';
};

export type Filter = {
  field: string;
  operator: string;
  value: string | number | boolean | undefined;
  unitSystem: UnitSystem | undefined; // is set for SIUnits only
};

export type LinkOperator = 'and' | 'or';

export enum DataSelectionReason {
  MapClick = 'mapClick',
  TableClick = 'tableClick',
  Related = 'related', // related well events
  Search = 'search', // searchbar
  Url = 'url', // URL on page load
  ConnectedAssets = 'connectedAssets',
  Embedded = 'embedded',
}
export const dataSelectionReasons = Object.values(DataSelectionReason);
export enum DataMultiSelectionReason {
  MultiSelection = 'multiSelection', // box selection, radius/polygon tool
  List = 'list',
  LinkedIds = 'linkedIds',
}
export function isSingleSelectionReason(
  arg: unknown
): arg is DataSelectionReason {
  return (
    isString(arg) && dataSelectionReasons.includes(arg as DataSelectionReason)
  );
}

export function getMapDataFields(item: WorkspaceItem): string[] {
  return uniq([
    ...(item.dataSource.type === 'api'
      ? item.dataSource.requiredMapDataFields
      : []),
    ...getExtraVisualizedFields(item),
  ]);
}

function normalizeFilters(filters: Filter[]): Filter[] {
  return filters
    .filter((filter) => !isNil(filter.value))
    .map((filter) => ({
      ...filter,
      value: isString(filter.value)
        ? filter.value.toLowerCase().trim()
        : filter.value,
    }));
}

export function areFiltersApplied(filters: Filter[]): boolean {
  return filters.some((filter) => !isNil(filter.value));
}
