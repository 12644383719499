/**
 * This implementation is strongly coupled with the react-console-emulator package.
 * We only use CLI for debugging, so it's OK to cut some corners here.
 * {@link https://github.com/linuswillner/react-console-emulator}
 */

import { dependencies } from '@pn/core/dependencies';
import { UserPlans } from '@pn/core/domain/user';
import { useExportGeoJSONCommand } from '@pn/core/operations/cli/export-geojson';
import { useGptInferCommand } from '@pn/core/operations/cli/gptInfer';
import { useGptToSqlCommand } from '@pn/core/operations/cli/gptToSql';
import { printMap } from '@pn/core/operations/cli/print-map';
import { useSqlCommand } from '@pn/core/operations/cli/sql';
import { isInsufficientPlan } from '@pn/core/permissions/access';
import { useCurrentUserStorage } from '@pn/core/storage';
import { getCurrentUserId } from '@pn/core/storage/user/currentUserStorage';
import {
  isPNTheme,
  usePNThemeContext,
} from '@pn/ui/theme/PetroNinjaThemeProvider';
import assert from 'assert';

type CliCommand = {
  access: {
    denied: boolean;
    message: string;
  };
  description: string;
  fn: (...args: string[]) => string | void | Promise<string | void>;
};

export function useCliCommands(): Record<string, CliCommand> {
  const { store, map, errorLogger } = dependencies;

  const { user } = useCurrentUserStorage();
  assert(user, 'User must be defined to use CLI commands.');

  const { setPNTheme } = usePNThemeContext();

  const isPro = {
    denied: isInsufficientPlan(user.userPlan, UserPlans.Professional),
    message: 'Error: Professional or Enterprise plan is required.',
  };
  const isEnterpriseUser = {
    denied: isInsufficientPlan(user.userPlan, UserPlans.EnterpriseMember),
    message: 'Error: Enterprise plan is required.',
  };
  const isAdmin = {
    denied: !user.isAdmin,
    message: 'Error: Administrator access is required.',
  };
  const isInDev = {
    denied: !user.isAdmin,
    message: 'Error: Feature in development. Special access is required.',
  };

  const commands: Record<string, CliCommand> = {
    'view-store': {
      access: isAdmin,
      description: 'Dump the store to the console.',
      fn: function () {
        console.log(store.getState());
        return 'Store dumped to the console.';
      },
    },
    'view-map-style': {
      access: isAdmin,
      description: 'Dump the map style to the console.',
      fn: function () {
        console.log(map._native.getStyle());
        return `Map style dumped to the console.`;
      },
    },
    'view-zoom': {
      access: isPro,
      description: 'View the current zoom level.',
      fn: function () {
        const zoom = map._native.getZoom();
        return `Zoom level: ${zoom}.`;
      },
    },
    'set-zoom': {
      access: isPro,
      description: 'Set the zoom level.',
      fn: function (zoom: string) {
        map._native.setZoom(Number(zoom));
        return `Zoom level set: ${zoom}.`;
      },
    },
    'export-geojson': {
      access: isPro,
      description: 'Export current layer/selection as GeoJSON.',
      fn: useExportGeoJSONCommand(),
    },
    // 'buffer-zones': {
    //   access: isAdmin,
    //   description: 'Generate a buffer around selected features.',
    //   fn: useBufferZonesCommand(),
    // },
    'print-map': {
      access: isInDev,
      description: 'Generate high-resolution PDF print of the map.',
      fn: async function (...args: string[]): Promise<string> {
        const printDimensions: [number, number] = [
          Number(args[0]),
          Number(args[1]),
        ];

        if (printDimensions.some((dim) => isNaN(dim) || dim <= 0)) {
          return 'Error: invalid print dimensions.';
        }

        return printMap(printDimensions);
      },
    },
    sql: {
      access: isInDev,
      description: 'Generate an SQL-driven layer.',
      fn: useSqlCommand(),
    },
    'gpt-to-sql': {
      access: isEnterpriseUser, // locked behind the "pn_feature_gpt" role on the API
      description: 'Generate an SQL query from user prompt.',
      fn: useGptToSqlCommand(),
    },
    'gpt-infer': {
      access: isInDev,
      description: 'Infer the data type from user prompt.',
      fn: useGptInferCommand(),
    },
    'set-theme': {
      access: isPro,
      description: 'Set the app theme.',
      fn: function (...args: string[]) {
        const theme = args.join(' ');
        if (!isPNTheme(theme)) return 'Error: invalid theme identifier.';
        setPNTheme(theme);
        return `Theme set: ${theme}.`;
      },
    },
    'test-notification': {
      access: isAdmin,
      description: 'Show a test notification.',
      fn: function () {
        dependencies.notificationService.error('Test notification!');
        // dependencies.notificationService.render({
        //   content: 'Persistent test notification',
        //   options: {
        //     showCloseButton: true,
        //   },
        // });
        return 'Test notification shown.';
      },
    },
    'test-error': {
      access: isAdmin,
      description: 'Trigger a test error.',
      fn: function () {
        errorLogger.logGenericError(
          new Error('Test error triggered'),
          getCurrentUserId(),
          'TestError'
        );
        return 'Test error triggered and logged.';
      },
    },
    'god-mode': {
      access: isPro,
      description: 'Activate god mode.',
      fn: function () {
        window.location.href = 'https://youtu.be/dQw4w9WgXcQ';
      },
    },
  };

  return Object.entries(commands).reduce<Record<string, CliCommand>>(
    (acc, [key, command]) => {
      acc[key] = {
        ...command,
        fn: (...args: string[]) => {
          if (command.access.denied) return command.access.message;
          return command.fn(...args);
        },
      };
      return acc;
    },
    {}
  );
}
