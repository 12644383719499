import {
  type Mapping,
  MappingItem,
  pickMappingItemsFromFields,
} from '@pn/core/domain/data';
import type { Filter } from '@pn/core/domain/query';
import type { UnitSystem } from '@pn/core/domain/types';
import { toSIUnit, undoUnitConversion } from '@pn/core/domain/units';
import type { IQueryMapper } from '@pn/core/mappers/query';
import assert from 'assert';
import { isNumber } from 'lodash-es';
import type { ApiElasticQuery } from './types';

export const apiElasticQueryMapper = (
  mapping: Mapping
): IQueryMapper<ApiElasticQuery> => {
  return {
    toTargetQuery: (query) => {
      return {
        dataType: query.dataType,
        fields: pickMappingItemsFromFields(mapping, query.fields).map(
          (mappingItem) =>
            toElasticField({
              mappingItem,
              purpose: 'fields',
            })
        ),
        sorts:
          'sorts' in query
            ? query.sorts.map((sort) => ({
                ...sort,
                field: toElasticField({
                  mappingItem: mapping[sort.field]!,
                  purpose: 'sorting',
                }),
              }))
            : [],
        filters: query.filters.map((filter) => ({
          ...filter,
          value: toElasticFilterValue({
            mappingItem: mapping[filter.field]!,
            fromValue: filter.value,
            unitSystem: filter.unitSystem,
          }),
          field: toElasticField({
            mappingItem: mapping[filter.field]!,
            purpose: 'filtering',
          }),
        })),
        filtersLinkOperator: query.filtersLinkOperator,
        requestedIds: query.requestedIds,
        ...('sql' in query && { sql: query.sql }),
        ...('page' in query && { page: query.page }),
        ...('pageSize' in query && { pageSize: query.pageSize }),
      };
    },
  };
};

function toElasticField(params: {
  mappingItem: MappingItem;
  purpose: 'fields' | 'sorting' | 'filtering';
}): string {
  const { mappingItem, purpose } = params;

  if (purpose === 'sorting' && mappingItem.sourceType === 'text') {
    return `${mappingItem.field}.keyword`;
  } else {
    return mappingItem.field;
  }
}

function toElasticFilterValue(params: {
  mappingItem: MappingItem;
  fromValue: Filter['value'];
  unitSystem: UnitSystem | undefined;
}): Filter['value'] {
  const { unitSystem, mappingItem, fromValue } = params;

  switch (mappingItem.domainType) {
    case 'string':
      if (mappingItem.sourceType === 'keyword') {
        return `${fromValue}*`; // do not add leading * to avoid performance issues
      } else {
        return fromValue;
      }
    case 'SIUnit': {
      assert(isNumber(fromValue), 'fromValue must be a number');
      assert(unitSystem, 'unitSystem must be defined');

      const unit = toSIUnit({
        value: fromValue,
        symbol: mappingItem.domainTypeAttributes.symbol,
      });

      return undoUnitConversion(unit, unitSystem).value;
    }
    default:
      return fromValue;
  }
}
