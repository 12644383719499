import type { PostgresMappingItem } from '@pn/services/api/data/types';

export const wellLicenceMismatches: Record<string, PostgresMappingItem> = {
  license_date: {
    field: 'license_date',
    sourceType: 'timestamp',
    domainType: 'DateString',
    label: 'Licence Date',
    width: 110,
    isShownByDefault: true,
  },
  id: {
    field: 'id',
    label: 'Licence Number',
    width: 100,
    sourceType: 'int',
    isShownByDefault: true,
  },
  licensee: {
    field: 'licensee',
    label: 'Licensee',
    isShownByDefault: true,
    sourceType: 'text',
  },
  projected_formation: {
    field: 'projected_formation',
    label: 'Formations',
    isShownByDefault: true,
    sourceType: 'text',
  },
  fields: {
    field: 'fields',
    label: 'Field',
    isShownByDefault: true,
    sourceType: 'text',
  },
  surface_uwi: {
    field: 'surface_uwi',
    label: 'Surface Location',
    isShownByDefault: true,
    width: 140,
    sourceType: 'text',
  },
  province: {
    field: 'province',
    label: 'Province',
    sourceType: 'text',
    isShownByDefault: true,
    width: 60,
  },
  uwis: {
    field: 'uwis',
    label: 'UWIs',
    isShownByDefault: true,
    width: 200,
    sourceType: 'text',
  },
  mineral_rights_owners: {
    field: 'mineral_rights_owners',
    label: 'Mineral Rights Owners',
    isShownByDefault: true,
    width: 300,
    sourceType: 'text',
  },
  represented_companies: {
    field: 'represented_companies',
    label: 'Represented Company',
    width: 300,
    sourceType: 'text',
  },
};
