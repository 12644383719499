import { dependencies } from '@pn/core/dependencies';
import {
  formatDataType,
  Mapping,
  toMappingItem,
  type DataItemId,
} from '@pn/core/domain/data';
import { geoShapeToGeometry, type GeoShape } from '@pn/core/domain/geography';
import { MapMode } from '@pn/core/domain/map';
import { DataMultiSelectionReason } from '@pn/core/domain/query';
import { UnitSystem } from '@pn/core/domain/types';
import {
  createWorkspaceItem,
  decomposeLayerId,
  getDataItemSelected,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { ApplicationError } from '@pn/core/errors';
import { exportWorkspaceItem } from '@pn/core/operations/workspace';
import { getMapDataItems } from '@pn/core/operations/workspace/mapData';
import {
  useCurrentUserStorage,
  useMapStorage,
  useWorkspaceStorage,
  workspaceActions,
} from '@pn/core/storage';
import { generateTemporaryId } from '@pn/core/utils/id';
import { muiColorPalette } from '@pn/services/color/colorPalettes';
import { downloadDataItems } from '@pn/services/exports/data/downloadDataItems';
import {
  getMapboxFeatureId,
  isMapboxDataFeature,
} from '@pn/services/map/mapbox/mappers/mapboxMapFeatureMapper';
import { isWithin } from '@pn/services/utils/geojson';
import * as turf from '@turf/turf';
import assert from 'assert';
import { difference, isEmpty, isNil, sortBy, uniq } from 'lodash-es';

type BufferZonesParams = {
  inputDistances: number[];
  listNames?: string[];
};

// TODO silence streaming notifications from lists?

export function useBufferZones() {
  const { map } = dependencies;

  const { user } = useCurrentUserStorage();
  const { workspaceItemSelected, idsInWorkspace, allWorkspaceItems } =
    useWorkspaceStorage();
  const { mapMode } = useMapStorage();

  const disabled =
    isNil(workspaceItemSelected) ||
    !['wells', 'wells_usa'].includes(workspaceItemSelected.dataType);

  const generateBufferZones = async ({
    inputDistances,
    listNames = [],
  }: BufferZonesParams): Promise<(fileName: string) => void> => {
    if (isEmpty(inputDistances))
      throw new ApplicationError('No buffer distance(s) provided');
    if (inputDistances.some((d) => d <= 0))
      throw new ApplicationError('Invalid distance(s)');
    if (isNil(workspaceItemSelected))
      throw new ApplicationError('No layer selected');
    if (!['wells', 'wells_usa'].includes(workspaceItemSelected.dataType))
      throw new ApplicationError(
        'Buffer zones can only be generated for wells'
      );

    const distances = sortBy(inputDistances);

    /* Clear previous buffer layer & lists */

    allWorkspaceItems
      .filter((item) => item.id.startsWith('buffer_'))
      .forEach((item) => workspaceActions().remove(item));

    /* Generate buffer layer */

    const dataItemSelected = getDataItemSelected(workspaceItemSelected);
    const mapDataItems = getMapDataItems(workspaceItemSelected);
    const dataItems =
      isEmpty(mapDataItems) && !isNil(dataItemSelected)
        ? [dataItemSelected]
        : mapDataItems;
    if (isEmpty(dataItems))
      throw new ApplicationError(
        `No ${formatDataType(workspaceItemSelected.dataType)} selected`
      );

    const inputGeometry = dataItems.reduce<GeoJSON.MultiLineString>(
      (acc, item) => {
        const wellPathGeoShape = item.well_path_geometry as
          | GeoShape
          | undefined;
        if (isNil(wellPathGeoShape)) {
          assert(item.geoShape, 'Target element must have a geoShape');

          const geoJsonPoint = geoShapeToGeometry(
            item.geoShape
          ) as GeoJSON.Point;
          const pt = geoJsonPoint.coordinates;
          acc.coordinates.push([pt, pt]); // generate a fake line
          return acc;
        }

        const wellPathGeometry = geoShapeToGeometry(
          wellPathGeoShape
        ) as GeoJSON.MultiLineString;

        acc.coordinates.push(...wellPathGeometry.coordinates);
        return acc;
      },
      { type: 'MultiLineString', coordinates: [] }
    );

    const buffers = distances
      .map((distance) =>
        turf.buffer(inputGeometry, distance, {
          units: 'meters',
        })
      )
      .filter((buffer): buffer is NonNullable<typeof buffer> => !isNil(buffer));
    if (isEmpty(buffers))
      throw new Error('Buffer zones generation failed: empty buffers');

    const bufferItem = createWorkspaceItem(
      {
        source: 'layer',
        id: `buffer_${generateTemporaryId()}`,
        name: `Buffer${distances.length > 1 ? 's' : ''} ${distances.join(' m, ')} m`,
        data: {
          type: 'FeatureCollection',
          features: buffers,
        },
        extraStyle:
          mapMode === MapMode.Dark
            ? {
                color: 'hsla(25, 100%, 60%, 0.2)',
                outlineColor: 'hsl(25, 40%, 60%)',
              }
            : {
                color: 'hsla(25, 100%, 50%, 0.2)',
                outlineColor: 'hsl(25, 40%, 30%)',
              },
        metadata: {
          isNonInteractive: true,
        },
      },
      user
    );

    workspaceActions().create(bufferItem);

    /* Generate buffer lists */

    const listItems: WorkspaceItem[] = [];
    const allRequestedIds: DataItemId[] = [];

    buffers.forEach((buffer, index) => {
      const bbox = turf.bbox(buffer);
      const mapboxBbox: [mapboxgl.Point, mapboxgl.Point] = [
        map._native.project([bbox[0], bbox[1]]),
        map._native.project([bbox[2], bbox[3]]),
      ];

      const matchedMapFeatures = map._native.queryRenderedFeatures(mapboxBbox);
      const intersectingMapFeatures = matchedMapFeatures.filter(
        (f) =>
          isMapboxDataFeature(f) &&
          f.layer.type === 'symbol' &&
          ['wells', 'wells_usa'].includes(
            decomposeLayerId(f.layer.id).dataType
          ) &&
          isWithin(buffer.geometry, f.geometry)
      );

      const ids = uniq(intersectingMapFeatures.map(getMapboxFeatureId));
      const requestedIds = difference(ids, [
        ...dataItems.map((d) => d._id),
        ...listItems.flatMap((item) => item.query.requestedIds),
      ]); // exclude elements from previous lists

      allRequestedIds.push(...requestedIds);

      const listItem = createWorkspaceItem(
        {
          source: 'item',
          sourceItem: workspaceItemSelected.sourceItem ?? workspaceItemSelected,
          id: `buffer_${generateTemporaryId()}`,
          name: listNames[index] ?? `Buffer zone ${index + 1}`,
          queryOptions: {
            requestedIds,
            multiSelectionReason: DataMultiSelectionReason.List,
          },
          extraStyle: {
            color: muiColorPalette.colorsStaggered[index + 1],
          },
        },
        user
      );

      workspaceActions().add(listItem);
      listItems.push(listItem);
    });

    /* Insert new items into the workspace beneath the selected layer */

    const listItemIds = listItems.map((item) => item.id);

    const indexOfSelectedItem = idsInWorkspace.indexOf(
      workspaceItemSelected.id
    );
    const updatedIds =
      indexOfSelectedItem > -1
        ? [
            ...idsInWorkspace.slice(0, indexOfSelectedItem),
            bufferItem.id,
            ...listItemIds,
            ...idsInWorkspace.slice(indexOfSelectedItem),
          ]
        : [...idsInWorkspace, bufferItem.id, ...listItemIds];

    workspaceActions().setWorkspace(updatedIds);

    /* Generate and return a report */

    const ZONE_FIELD = '__zone';

    const exportUnits =
      workspaceItemSelected.dataType === 'wells'
        ? UnitSystem.Metric
        : UnitSystem.Imperial;
    const exportFields =
      workspaceItemSelected.dataType === 'wells'
        ? [
            ZONE_FIELD,
            'internal_id',
            'formatted_well_id',
            'licensee',
            'license_number',
            'surface_location',
            'producing_formation',
            'well_fluid',
            'well_type',
            'well_status',
            'tmd',
            'tvd',
            'last_activity_date',
          ]
        : [
            ZONE_FIELD,
            'internal_id',
            'state',
            'current_operator_name',
            'well_status',
            'formation_name',
            'production_last_prod_date',
            'total_measured_depth_ft',
            'total_vertical_depth_ft',
          ];

    const exportMapping: Mapping = {
      ...workspaceItemSelected.mapping,
      [ZONE_FIELD]: toMappingItem({
        source: '',
        field: ZONE_FIELD,
        domainType: 'string',
        ui: {
          label: 'Zone',
        },
        sourceType: '', // TODO remove when we get rid of ElasticSearch
      }),
    };

    const exportItem = createWorkspaceItem(
      {
        source: 'item',
        sourceItem: workspaceItemSelected.sourceItem ?? workspaceItemSelected,
        queryOptions: {
          requestedIds: allRequestedIds,
        },
      },
      undefined
    );

    const { data } = await exportWorkspaceItem({
      item: exportItem,
      fields: exportFields,
      silent: true,
    });

    const listIdsToNames = new Map<DataItemId, string>(
      listItems.flatMap((item) =>
        item.query.requestedIds.map((id) => [id, item.name])
      )
    );

    const exportData = sortBy(
      data.map((d) => ({
        ...d,
        [ZONE_FIELD]: listIdsToNames.get(d._id),
      })),
      ZONE_FIELD
    );

    return (fileName: string) =>
      downloadDataItems({
        data: exportData,
        fields: exportFields,
        mapping: exportMapping,
        unitSystem: exportUnits,
      }).csv({ fileName });
  };

  return { disabled, generateBufferZones };
}
