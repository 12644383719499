import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import SpaceDashboardOutlinedIcon from '@mui/icons-material/SpaceDashboardOutlined';
import TerrainIcon from '@mui/icons-material/Terrain';
import TuneIcon from '@mui/icons-material/Tune';
import WaterDropOutlinedIcon from '@mui/icons-material/WaterDropOutlined';
import { Badge, Box, Button } from '@mui/material';
import {
  GridSlotProps,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import {
  getDefaultFieldsFromMapping,
  type DataItemId,
} from '@pn/core/domain/data';
import {
  isNonStreamableItem,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { useTableFields } from '@pn/core/operations/dataTableInteractions';
import { getMapDataItems } from '@pn/core/operations/workspace/mapData';
import { isEditable } from '@pn/core/permissions/editability';
import { useCurrentUserStorage, useWorkspaceStorage } from '@pn/core/storage';
import { isCordova } from '@pn/core/utils/env';
import { arraysAreIdentical } from '@pn/core/utils/logic';
import { webStorageService } from '@pn/services/storage/webStorageService';
import { PermissionButton } from '@pn/ui/custom-components/permission/PermissionButton';
import { PreferencesPopover } from '@pn/ui/data-table/components/PreferencesPopover';
import { ZonalQueryDialog } from '@pn/ui/data-table/components/zonal-query/ZonalQueryDialog';
import { ColorfulStarsIcon } from '@pn/ui/icons/ColorfulStarsIcon';
import { useWorkspaceItemPanel } from '@pn/ui/workspace/WorkspaceItemPanelProvider';
import assert from 'assert';
import { isNil } from 'lodash-es';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { checkIsExportDisabled } from '../featuresDisabled';
import { ColumnsDialog } from './ColumnsDialog';
import { ExportsDialog } from './ExportsDialog';
import { LayerTitle } from './LayerTitle';

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    gap: theme.spacing(1),
  },
  button: {
    height: 34,
  },
}));

export type ToolbarProps = {
  show?: boolean;
  showColumnsSelector?: boolean;
  showStyleButton?: boolean;
};

type Props = GridSlotProps['toolbar'] &
  ToolbarProps & {
    isToolbarDisabled: boolean;
    showHeaderFilters: boolean;
    setShowHeaderFilters: (show: boolean) => void;
  };

// DO NOT WRAP WITH REACT.MEMO()
export const CustomTableToolbar = ({
  isToolbarDisabled,
  show = true,
  showColumnsSelector = false,
  showStyleButton = false,
  showHeaderFilters,
  setShowHeaderFilters,
}: Props) => {
  const { classes } = useStyles();

  const { user } = useCurrentUserStorage();
  const { workspaceItemSelected } = useWorkspaceStorage();
  const { fields } = useTableFields(workspaceItemSelected?.dataType ?? '');

  const defaultFields = React.useMemo(
    () => getDefaultFieldsFromMapping(workspaceItemSelected?.mapping ?? {}),
    [workspaceItemSelected?.mapping]
  );

  const { openWorkspaceItemPanel } = useWorkspaceItemPanel();

  const isLoading =
    !isNil(workspaceItemSelected) &&
    !workspaceItemSelected.isMappingInitialized;

  const isExportDisabled = checkIsExportDisabled({
    isToolbarDisabled,
    totalCount: workspaceItemSelected?.totalCount ?? 0,
  });

  const isStyleButtonDisabled =
    isNil(workspaceItemSelected) ||
    !isEditable(workspaceItemSelected, user) ||
    ['annotation', 'drawing'].includes(workspaceItemSelected.itemType);

  const [isColumnsDialogOpen, setIsColumnsDialogOpen] = React.useState(false);
  const [isExportsDialogOpen, setIsExportsDialogOpen] = React.useState(false);
  const [isZonalQueryDialogOpen, setIsZonalQueryDialogOpen] =
    React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();

  const isZonalQueryAvailable =
    !isNil(workspaceItemSelected) &&
    ['mineral_rights', 'open_mineral_rights'].includes(
      workspaceItemSelected.dataType
    );

  const handleOpenAggregateProduction = () => {
    assert(
      workspaceItemSelected?.dataType === 'wells',
      'production view is only available for Canadian wells'
    );

    const wellIds = getDataItemIds(workspaceItemSelected);

    webStorageService('pn').set('production-well-ids', wellIds);

    window.open('/production/aggregate', '_blank');
  };

  if (!show) return null;

  return (
    <GridToolbarContainer className={classes.container}>
      <LayerTitle />

      {showStyleButton && (
        <Button
          size="small"
          className={classes.button}
          disabled={isStyleButtonDisabled}
          onClick={() => {
            assert(workspaceItemSelected, 'workspaceItemSelected is required');
            openWorkspaceItemPanel({
              item: workspaceItemSelected,
              tab: 'Style',
            });
          }}
          startIcon={
            <ColorfulStarsIcon
              disabled={isStyleButtonDisabled}
              style={{ fontSize: 20 }}
            />
          }
        >
          Style
        </Button>
      )}

      {showColumnsSelector && (
        <Button
          size="small"
          className={classes.button}
          disabled={isToolbarDisabled}
          onClick={() => setIsColumnsDialogOpen(true)}
          startIcon={
            <Badge
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              variant="dot"
              color="secondary"
              invisible={isLoading || arraysAreIdentical(defaultFields, fields)}
            >
              <SpaceDashboardOutlinedIcon style={{ fontSize: 20 }} />
            </Badge>
          }
        >
          Columns
        </Button>
      )}

      <GridToolbarFilterButton
        slotProps={{
          button: {
            disabled: isToolbarDisabled,
            className: classes.button,
          },
          tooltip: {
            open: false,
          },
        }}
      />

      {!isCordova() && (
        <PermissionButton
          permissionPath="dataTable.export"
          size="small"
          color="primary"
          className={classes.button}
          startIcon={<FileDownloadOutlinedIcon style={{ fontSize: 24 }} />}
          disabled={isToolbarDisabled || isNil(workspaceItemSelected)}
          onClick={() => setIsExportsDialogOpen(true)}
        >
          Exports
        </PermissionButton>
      )}

      {!isCordova() && workspaceItemSelected?.dataType === 'wells' && (
        <PermissionButton
          permissionPath="production.aggregate"
          size="small"
          color="primary"
          className={classes.button}
          startIcon={<WaterDropOutlinedIcon style={{ fontSize: 22 }} />}
          disabled={isExportDisabled}
          onClick={() => handleOpenAggregateProduction()}
        >
          Production
        </PermissionButton>
      )}

      {isZonalQueryAvailable && (
        <PermissionButton
          permissionPath="dataTable.filtering"
          size="small"
          color="primary"
          className={classes.button}
          startIcon={<TerrainIcon style={{ fontSize: 22 }} />}
          onClick={() => setIsZonalQueryDialogOpen(true)}
        >
          Zone Query
        </PermissionButton>
      )}

      <Box ml="auto">
        <Button
          size="small"
          className={classes.button}
          startIcon={<TuneIcon style={{ fontSize: 20 }} />}
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          Settings
        </Button>
      </Box>

      <PreferencesPopover
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        showHeaderFilters={showHeaderFilters}
        setShowHeaderFilters={setShowHeaderFilters}
      />

      <ColumnsDialog
        open={isColumnsDialogOpen}
        onClose={() => setIsColumnsDialogOpen(false)}
      />
      {workspaceItemSelected && (
        <ExportsDialog
          item={workspaceItemSelected}
          isDownloadDisabled={isExportDisabled}
          open={isExportsDialogOpen}
          onClose={() => setIsExportsDialogOpen(false)}
        />
      )}
      {isZonalQueryAvailable && (
        <ZonalQueryDialog
          item={workspaceItemSelected}
          open={isZonalQueryDialogOpen}
          onOpen={() => setIsZonalQueryDialogOpen(true)}
          onClose={() => setIsZonalQueryDialogOpen(false)}
        />
      )}
    </GridToolbarContainer>
  );
};

function getDataItemIds(item: WorkspaceItem): DataItemId[] {
  if (isNonStreamableItem(item)) {
    return item.query.requestedIds;
  } else {
    return getMapDataItems(item).map(({ _id }) => _id);
  }
}
