import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from '@mui/material';
import {
  LayerType,
  isInteractableLayer,
  type Layer,
} from '@pn/core/domain/layer';
import {
  getColorComponents,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { useDynamicStyling } from '@pn/core/operations/workspace';
import { getQuickDynamicStylingPresets } from '@pn/core/operations/workspace/quickDynamicStylingPresets';
import { workspaceActions } from '@pn/core/storage';
import { ColorPicker } from '@pn/ui/color-picker';
import { LARGE_SQ_GAP } from '@pn/ui/custom-components/color-picker/ColorSelector';
import { CustomTooltip } from '@pn/ui/custom-components/CustomTooltip';
import { OpacityInputWithToggles } from '@pn/ui/inputs/OpacityInputWithToggles';
import Color from 'color';
import { isArray, isEmpty, isNil, isString } from 'lodash-es';
import React from 'react';
import { StyleByAttributeSelector } from 'src/ui/workspace/styling/StyleByAttributeSelector';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  bubbleMapSwitch: {
    marginRight: 0,
  },
  presetButton: {
    textTransform: 'none',
  },
}));

type Props = {
  item: WorkspaceItem;
  layer: Layer;
  disabled: boolean;
  updateAll?: boolean;
  showAlphaSlider?: boolean;
};

export const CombinedColorPicker = ({
  item,
  layer,
  disabled,
  updateAll = false,
  showAlphaSlider = false,
}: Props) => {
  const { classes } = useStyles();

  const colorComponents = React.useMemo(
    () => getColorComponents(layer.style.color),
    [layer.style.color]
  );

  const mappingItem = React.useMemo(
    () => item.mapping[colorComponents.field ?? ''],
    [item.mapping, colorComponents.field]
  );

  const [mode, setMode] = React.useState(
    isEmpty(colorComponents) ? 'simple' : 'dynamic'
  );

  const canBubbleMap =
    mode === 'dynamic' &&
    [LayerType.Icon, LayerType.Circle].includes(layer.type) &&
    !isNil(mappingItem) &&
    ['number', 'SIUnit'].includes(mappingItem.domainType);

  const dynamicStyling = useDynamicStyling({ item, layer, updateAll });
  const {
    isDynamicStylingDisabled,
    isBubbleMapEnabled,
    setIsBubbleMapEnabled,
    applyDynamicStyling,
  } = dynamicStyling;

  const handleUpdateColor = (value: unknown) => {
    if (updateAll) {
      workspaceActions().updateAllLayerStyles(item.id, { color: value });
    } else {
      workspaceActions().updateLayerStyle(item.id, layer.id, { color: value });
    }

    if (isString(value) && isArray(layer.style.radius)) {
      workspaceActions().disableBubbleMapping(item.id);
    }
  };

  if (!isInteractableLayer(layer)) return null;

  return (
    <>
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <FormControl>
            <RadioGroup
              row
              name="extended-color-picker-mode-group"
              value={mode}
              onChange={(event) => setMode(event.target.value)}
            >
              <FormControlLabel
                disabled={disabled}
                value="simple"
                label="Simple"
                control={<Radio />}
              />
              <FormControlLabel
                disabled={disabled}
                value="dynamic"
                label="Dynamic"
                control={<Radio />}
              />
            </RadioGroup>
          </FormControl>
          <CustomTooltip
            disabled={canBubbleMap}
            title={
              ![LayerType.Icon, LayerType.Circle].includes(layer.type)
                ? 'Supported by icon and circle layers only'
                : mode === 'simple'
                  ? 'Supported in dynamic mode only'
                  : 'Supported by numeric fields only'
            }
          >
            <FormControlLabel
              disabled={!canBubbleMap}
              control={
                <Switch
                  checked={canBubbleMap && isBubbleMapEnabled}
                  onChange={(event) => {
                    setIsBubbleMapEnabled(event.target.checked);
                    applyDynamicStyling({
                      _isBubbleMapEnabled: event.target.checked,
                    });
                  }}
                />
              }
              label="Bubble map"
              className={classes.bubbleMapSwitch}
            />
          </CustomTooltip>
        </Box>
        <Box mt={LARGE_SQ_GAP * 2 + 'px'}>
          {mode === 'simple' ? (
            <>
              <ColorPicker
                disabled={disabled}
                value={
                  isString(layer.style.color)
                    ? Color(layer.style.color).alpha(1).hex()
                    : '#888'
                }
                onChange={(color) =>
                  handleUpdateColor(
                    isString(layer.style.color) &&
                      Color(layer.style.color).alpha() < 1
                      ? Color(color)
                          .alpha(Color(layer.style.color).alpha())
                          .rgb()
                          .string()
                      : color
                  )
                }
              />
              {showAlphaSlider && (
                <Box mt={2}>
                  <OpacityInputWithToggles
                    disabled={!isString(layer.style.color)}
                    value={
                      isString(layer.style.color)
                        ? Color(layer.style.color).alpha() * 100
                        : 100
                    }
                    onChange={(opacity) =>
                      handleUpdateColor(
                        opacity < 100
                          ? Color(layer.style.color as string)
                              .alpha(opacity / 100)
                              .rgb()
                              .string()
                          : Color(layer.style.color as string).hex()
                      )
                    }
                  />
                  {!isString(layer.style.color) && (
                    <Box mt={1}>
                      <Typography variant="body2" color="textSecondary">
                        Cannot change opacity with Dynamic styling applied.
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
            </>
          ) : (
            <StyleByAttributeSelector
              disabled={disabled || isDynamicStylingDisabled}
              item={item}
              layer={layer}
              {...dynamicStyling}
            />
          )}
        </Box>
      </Box>
      {updateAll && !isEmpty(getQuickDynamicStylingPresets(item.dataType)) && (
        <Box display="flex" flexWrap="wrap" gap={1} mt={2}>
          {getQuickDynamicStylingPresets(item.dataType).map((preset) => (
            <Button
              key={preset.field}
              size="small"
              variant="outlined"
              className={classes.presetButton}
              disabled={disabled}
              startIcon={<AutoFixHighIcon />}
              onClick={() => {
                setMode('dynamic');
                applyDynamicStyling({ _field: preset.field });
              }}
            >
              By {preset.label}
            </Button>
          ))}
        </Box>
      )}
    </>
  );
};
