import type { MappingItem } from '@pn/core/domain/data';
import type { IMappingItemMapper, IMappingMapper } from '@pn/core/mappers/data';
import type { GptMappingItem } from '@pn/services/gpt/types';
import assert from 'assert';

const gptMappingItemMapper: IMappingItemMapper<GptMappingItem> = {
  toDomainMappingItem: () => {
    throw new Error('Not implemented');
  },
  toTargetMappingItem: (mappingItem) => {
    return {
      field: mappingItem.field,
      source: undefined, // mappingItem.source
      ...convertType(mappingItem),
      hint: mappingItem.gpt?.hint,
    };
  },
};

export const gptMappingMapper: IMappingMapper<GptMappingItem[]> = {
  toDomainMapping: () => {
    throw new Error('Not implemented');
  },
  toTargetMapping: (mapping) => {
    return Object.values(mapping).reduce<GptMappingItem[]>(
      (acc, mappingItem) => {
        assert(mappingItem, 'Mapping item is undefined');

        if (mappingItem.source !== 'regulator') return acc; // exclude StackDX mappings for now

        const gptMappingItem =
          gptMappingItemMapper.toTargetMappingItem(mappingItem);

        if (!mappingItem.gpt?.exclude && gptMappingItem.type !== 'null') {
          acc.push(gptMappingItem);
        }

        return acc;
      },
      []
    );
  },
};

/**
 * Simplify MappingItem's type and type attributes to save on request tokens.
 */
function convertType(mappingItem: MappingItem): {
  type: GptMappingItem['type'];
  units?: string;
  options?: string[];
} {
  switch (mappingItem.domainType) {
    case 'boolean':
      return { type: 'boolean' };
    case 'number':
      return { type: 'number' };
    case 'string':
      return {
        type: 'string',
        options: mappingItem.domainTypeAttributes?.options,
      };
    case 'DateString':
      return { type: 'Date' };
    case 'SIUnit':
      return {
        type: 'number',
        units: mappingItem.domainTypeAttributes.symbol,
      };
    default:
      return { type: 'null' }; // override all other types as nulls to filter them out
  }
}
