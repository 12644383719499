import {
  toMappingItem,
  type Mapping,
  type MappingItem,
} from '@pn/core/domain/data';
import type { PostgresMappingItem, PostgresSourceType } from './types';

function postgresSourceTypeToDomainType(
  sourceType: PostgresSourceType
): MappingItem['domainType'] {
  switch (sourceType) {
    case 'bool':
      return 'boolean';
    case 'int':
    case 'serial':
    case 'float':
      return 'number';
    case 'varchar':
    case 'text':
    case 'timestamp':
    case 'date':
      return 'string';
    case 'geometry':
      return 'GeoShape';
    case 'json':
    case 'jsonb':
      return 'object';
    default:
      throw new Error(`Invalid postgres source type: ${sourceType}`);
  }
}

export const postgresToDomainMapping = (
  postgresMapping: Record<string, PostgresMappingItem>
): Mapping => {
  return Object.entries(postgresMapping).reduce<Mapping>(
    (acc, [field, { sourceType, ...item }]) => {
      const domainType =
        item.domainType ?? postgresSourceTypeToDomainType(sourceType);

      acc[field] = toMappingItem({
        source: '',
        field,
        domainType,
        domainTypeAttributes:
          domainType === 'DateString' ? { format: 'yyyy-MM-dd' } : undefined,
        ui: {
          label: field,
          tooltip: item.tooltip,
          folder: item.folder,
          subFolder: item.subFolder,
          width: item.width,
          isShownByDefault: item.isShownByDefault,
          isNotSortable: item.isNotSortable,
          isNotRenderable: item.isNotRenderable,
        },
        sourceType,
      });

      return acc;
    },
    {}
  );
};
