import { type Mapping, toMappingItem } from '@pn/core/domain/data';
import type { IMappingMapper } from '@pn/core/mappers/data';
import { nullToUndefined } from '@pn/core/utils/logic';
import { parquetSourceTypeToDomainType } from '@pn/services/api/data/parquet';
import type { ApiParquetMappingItem } from '@pn/services/api/layer/types';

export const apiParquetMappingMapper: IMappingMapper<ApiParquetMappingItem[]> =
  {
    toDomainMapping: (apiParquetMapping) => {
      return apiParquetMapping.reduce<Mapping>(
        (acc, { field, data_type, is_not_renderable }) => {
          if (data_type === 'Null') return acc;

          const domainType = parquetSourceTypeToDomainType(data_type);

          acc[field] = toMappingItem({
            source: '',
            field,
            domainType,
            domainTypeAttributes:
              domainType === 'DateString'
                ? { format: 'yyyy-MM-dd' }
                : undefined,
            ui: {
              label: field,
              isShownByDefault: true,
              isNotRenderable: nullToUndefined(is_not_renderable),
            },
            sourceType: data_type,
          });

          return acc;
        },
        {}
      );
    },
    toTargetMapping: () => {
      throw new Error('Not implemented');
    },
  };
