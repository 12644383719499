import { Alert, Box, Typography } from '@mui/material';
import { formatDataType } from '@pn/core/domain/data';
import {
  isDynamicWorkspaceItem,
  isPartialDataReceived,
  type WorkspaceItem,
} from '@pn/core/domain/workspace';
import { getMapDataItems } from '@pn/core/operations/workspace/mapData';
import { isNil } from 'lodash-es';
import { RequestedIdsInput } from 'src/ui/workspace/components/RequestedIdsInput';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  label: {
    position: 'absolute',
    top: -8,
    left: 12,
    background: theme.palette.background.paper,
    paddingLeft: 4,
    paddingRight: 4,
    fontSize: 12,
    zIndex: 1,
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
}));

type Props = {
  disabled: boolean;
  item: WorkspaceItem;
  numberOfIds: number;
};

export const QueryOptionsComponent = ({
  disabled,
  item,
  numberOfIds,
}: Props) => {
  const { classes } = useStyles();

  if (!isDynamicWorkspaceItem(item)) {
    return (
      <RequestedIdsInput
        disabled={disabled}
        itemId={item.id}
        dataType={item.dataType}
        numberOfIds={numberOfIds}
        requestedIds={item.query.requestedIds}
      />
    );
  }

  return (
    <Box>
      {isPartialDataReceived(item) && (
        <Alert severity="warning" className={classes.alert}>
          You are saving a partial result.
        </Alert>
      )}
      <Box position="relative">
        <Typography color="textSecondary" className={classes.label}>
          Matched {getMapDataItems(item).length.toLocaleString()}{' '}
          {formatDataType(item.dataType)} from{' '}
          {!isNil(item.query.sql) ? 'SQL' : 'filters'}
        </Typography>
        <RequestedIdsInput
          disabled
          itemId={item.id}
          dataType={item.dataType}
          numberOfIds={numberOfIds}
          requestedIds={getMapDataItems(item).map(({ _id }) => _id)}
        />
      </Box>
    </Box>
  );
};
