import type { Mapping } from '@pn/core/domain/data';
import {
  generateTableTemplateName,
  type TableTemplate,
} from '@pn/core/domain/table';
import { tableActions, useTableStorage } from '@pn/core/storage';
import { generateTemporaryId } from '@pn/core/utils/id';
import { arraysAreIdentical } from '@pn/core/utils/logic';
import { isEmpty, orderBy } from 'lodash-es';
import React from 'react';

export function useTableTemplates(sourceLayerId: string) {
  const { isFetching, tableFields, selectedTemplates, templates } =
    useTableStorage();

  const sourceLayerTemplates = React.useMemo(
    () =>
      orderBy(
        templates.filter(
          (template) => template.sourceLayerId === sourceLayerId
        ),
        'createdAt',
        'desc'
      ),
    [sourceLayerId, templates]
  );

  const isTemplateSelected = (template: TableTemplate) => {
    const selectedTemplate = selectedTemplates[sourceLayerId];
    const currentFields = tableFields[sourceLayerId] ?? [];

    return (
      selectedTemplate?.id === template.id &&
      arraysAreIdentical(template.fields, currentFields)
    );
  };

  const selectTemplate = (template: TableTemplate) => {
    tableActions().select(template);
  };

  const createTemplate = (params: {
    name: string;
    fields: string[];
    mapping: Mapping;
  }) => {
    const { name, fields, mapping } = params;

    const nameAdjusted = isEmpty(name)
      ? generateTableTemplateName({ fields, mapping })
      : name;

    tableActions().create({
      id: generateTemporaryId(),
      name: nameAdjusted,
      sourceLayerId,
      fields,
      createdAt: new Date().toISOString(),
      isGlobal: false,
    });
  };

  const deleteTemplate = (template: TableTemplate) => {
    tableActions().remove(template);
  };

  return {
    isFetchingTemplates: isFetching,
    sourceLayerTemplates,
    isTemplateSelected,
    selectTemplate,
    createTemplate,
    deleteTemplate,
  };
}
