import type { DataItemId, MappingItem } from '@pn/core/domain/data';
import { geometryToGeoShape, type GeoShape } from '@pn/core/domain/geography';
import {
  isWKTString,
  type WKTString,
  wktToGeoShape,
} from '@pn/core/domain/types';
import { isGeoJSONGeometry } from '@pn/core/utils/geospatial';
import { isNil } from 'lodash-es';

export type ApiDataItem = {
  internal_id: DataItemId;
  geometry: GeoJSON.Geometry | WKTString | undefined;
  [key: string]: unknown;
};

export function apiGeometryToGeoShape(
  apiGeometry: ApiDataItem['geometry']
): GeoShape | undefined {
  if (isNil(apiGeometry)) return undefined;
  if (isGeoJSONGeometry(apiGeometry)) return geometryToGeoShape(apiGeometry);
  if (isWKTString(apiGeometry)) return wktToGeoShape(apiGeometry);
}

/**
 * All source mapping items must be derived from this type.
 */
export type RawMappingItem = Omit<MappingItem, 'field' | 'domainType'> & {
  domainType?: MappingItem['domainType']; // can be defined explicitly when necessary
  domainTypeAttributes?: MappingItem['domainTypeAttributes'];
};

export type ElasticSourceType =
  | 'boolean'
  | 'integer'
  | 'double'
  | 'date'
  | 'keyword'
  | 'text'
  | 'geo_shape';

export type ElasticMappingItem = RawMappingItem & {
  sourceType: ElasticSourceType;
};

export type PostgresSourceType =
  | 'bool'
  | 'int'
  | 'serial'
  | 'float'
  | 'varchar'
  | 'text'
  | 'date'
  | 'timestamp'
  | 'geometry'
  | 'json'
  | 'jsonb';

/**
 * @deprecated See postgres-mappings/new folder for the new .jsonc mapping files
 * (not currently used and incomplete).
 */
export type PostgresMappingItem = {
  sourceType: PostgresSourceType;
  domainType?: MappingItem['domainType'];
  domainTypeAttributes?: MappingItem['domainTypeAttributes'];
  field: string;
  label: string;
  tooltip?: string;
  folder?: string;
  subFolder?: string;
  width?: number;
  isShownByDefault?: true;
  isNotSortable?: true;
  isNotRenderable?: true;
};

export type ParquetSourceType =
  | 'Boolean'
  | 'Float32'
  | 'Float64'
  | 'Int8'
  | 'Int16'
  | 'Int32'
  | 'Int64'
  | 'String'
  | 'WKTString' // special type
  | 'Date'
  | 'Null';

export type ParquetMappingItem = RawMappingItem & {
  sourceType: ParquetSourceType;
};
