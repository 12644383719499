import { DialogContentText, Typography } from '@mui/material';
import { formatDataType } from '@pn/core/domain/data';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { isEmpty, isNil } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  list: {
    margin: 0,
    color: theme.palette.text.secondary,
  },
  semiBold: {
    fontWeight: 500,
  },
}));

type Props = {
  item: WorkspaceItem;
};

export const CurrentModifiers = ({ item }: Props) => {
  const { classes } = useStyles();

  const nonNilFilters = item.query.filters.filter(
    (filter) => !isNil(filter.value)
  );

  if (
    isEmpty(item.query.requestedIds) &&
    isEmpty(nonNilFilters) &&
    isNil(item.query.sql)
  ) {
    return null;
  }

  return (
    <>
      <DialogContentText>Current constraints:</DialogContentText>
      <ul className={classes.list}>
        {!isEmpty(item.query.requestedIds) && (
          <Typography component="li">
            Selected {formatDataType(item.dataType)}:{' '}
            <span className={classes.semiBold}>
              {item.query.requestedIds.length.toLocaleString()}
            </span>
          </Typography>
        )}
        {!isEmpty(nonNilFilters) && (
          <Typography component="li">
            Filters:{' '}
            <span className={classes.semiBold}>
              {nonNilFilters.map((filter) => filter.value).join(', ')}
            </span>
          </Typography>
        )}
        {!isNil(item.query.sql) && (
          <Typography component="li">SQL query applied.</Typography>
        )}
      </ul>
    </>
  );
};
