import { isEmpty, isNil } from 'lodash-es';

type Case = 'lower' | 'sentence';
type Form = 'singular' | 'plural';

export function formatDataType(
  dataType: string,
  options: { case?: Case; form?: Form } = {}
) {
  const { case: _case = 'lower', form: _form = 'plural' } = options;

  if (isNil(names[dataType])) {
    const stackDataType = dataType.split('|')[1];
    if (!isEmpty(stackDataType) && !isNil(stackNames[stackDataType])) {
      return stackNames[stackDataType][_case][_form];
    }

    return generic[_case][_form];
  } else {
    return names[dataType][_case][_form];
  }
}

const generic = {
  lower: {
    singular: 'element',
    plural: 'elements',
  },
  sentence: {
    singular: 'Element',
    plural: 'Elements',
  },
};

const names: Record<string, Record<Case, Record<Form, string>>> = {
  wells: {
    lower: {
      singular: 'well',
      plural: 'wells',
    },
    sentence: {
      singular: 'Well',
      plural: 'Wells',
    },
  },
  wells_usa: {
    lower: {
      singular: 'US well',
      plural: 'US wells',
    },
    sentence: {
      singular: 'US Well',
      plural: 'US Wells',
    },
  },
  pipelines: {
    lower: {
      singular: 'pipeline',
      plural: 'pipelines',
    },
    sentence: {
      singular: 'Pipeline',
      plural: 'Pipelines',
    },
  },
  facilities: {
    lower: {
      singular: 'facility',
      plural: 'facilities',
    },
    sentence: {
      singular: 'Facility',
      plural: 'Facilities',
    },
  },
  mineral_rights: {
    lower: {
      singular: 'mineral rights', // special case
      plural: 'mineral rights',
    },
    sentence: {
      singular: 'Mineral Rights', // special case
      plural: 'Mineral Rights',
    },
  },
  open_mineral_rights: {
    lower: {
      singular: 'open rights', // special case
      plural: 'open rights',
    },
    sentence: {
      singular: 'Open Rights', // special case
      plural: 'Open Rights',
    },
  },
  land_postings: {
    lower: {
      singular: 'land posting',
      plural: 'land postings',
    },
    sentence: {
      singular: 'Land Posting',
      plural: 'Land Postings',
    },
  },
  land_results: {
    lower: {
      singular: 'land result',
      plural: 'land results',
    },
    sentence: {
      singular: 'Land Result',
      plural: 'Land Results',
    },
  },
  units: {
    lower: {
      singular: 'unit',
      plural: 'units',
    },
    sentence: {
      singular: 'Unit',
      plural: 'Units',
    },
  },
  pools: {
    lower: {
      singular: 'pool',
      plural: 'pools',
    },
    sentence: {
      singular: 'Pool',
      plural: 'Pools',
    },
  },
  dispositions: {
    lower: {
      singular: 'disposition',
      plural: 'dispositions',
    },
    sentence: {
      singular: 'Disposition',
      plural: 'Dispositions',
    },
  },
  dispositions_historical: {
    lower: {
      singular: 'disposition',
      plural: 'dispositions',
    },
    sentence: {
      singular: 'Disposition',
      plural: 'Dispositions',
    },
  },
  grids: {
    lower: {
      singular: 'grid',
      plural: 'grids',
    },
    sentence: {
      singular: 'Grid',
      plural: 'Grids',
    },
  },
};

const stackNames: Record<string, Record<Case, Record<Form, string>>> = {
  mineralSplits: {
    lower: {
      singular: 'mineral element',
      plural: 'mineral elements',
    },
    sentence: {
      singular: 'Mineral element',
      plural: 'Mineral elements',
    },
  },
  contractSplits: {
    lower: {
      singular: 'contract element',
      plural: 'contract elements',
    },
    sentence: {
      singular: 'Contract element',
      plural: 'Contract elements',
    },
  },
  surface: {
    lower: {
      singular: 'surface element',
      plural: 'surface elements',
    },
    sentence: {
      singular: 'Surface element',
      plural: 'Surface elements',
    },
  },
};
