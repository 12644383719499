import type { DataItem, Mapping } from '@pn/core/domain/data';
import type { Details, KeyValueRow } from '@pn/core/domain/data-info';
import type { ILocalDataInfoProvider } from '@pn/core/providers/data-info/ports';
import { isNil } from 'lodash-es';

const getDataDetails = async (dataItem: DataItem, mapping: Mapping) => {
  const details: Details = [
    {
      tabName: 'Header',
      tables: [
        {
          title: 'Details',
          rows: Object.entries(mapping).reduce<KeyValueRow[]>(
            (acc, [field, mappingItem]) => {
              if (!isNil(mappingItem) && !mappingItem.ui.isNotRenderable) {
                acc.push({
                  key: mappingItem.ui.label,
                  value: dataItem[field],
                  // type: mappingItem.domainType, // QUESTION do we need this?
                  isButton: false,
                });
              }
              return acc;
            },
            []
          ),
          professional: false,
          noExport: false,
          disablePagination: false,
        },
      ],
    },
  ];

  return details;
};

export const localDataInfoProvider: ILocalDataInfoProvider = {
  getDataDetails,
};
