import type { PostgresMappingItem } from '@pn/services/api/data/types';

export const wellsByLiquidProductionMapping: Record<
  string,
  PostgresMappingItem
> = {
  wellbore_uwi: {
    field: 'wellbore_uwi',
    label: 'UWI',
    isShownByDefault: true,
    width: 175,
    sourceType: 'int',
  },
  licensee: {
    field: 'licensee',
    label: 'Licensee',
    width: 175,
    isShownByDefault: true,
    sourceType: 'text',
  },
  oil_and_condensate_bbl_per_day: {
    field: 'oil_and_condensate_bbl_per_day',
    label: 'Oil + Condensate',
    sourceType: 'float',
    isShownByDefault: true,
    domainType: 'SIUnit',
    domainTypeAttributes: { symbol: 'bbl/d' },
  },
  oil_bbl_per_day: {
    field: 'oil_bbl_per_day',
    label: 'Oil',
    sourceType: 'float',
    isShownByDefault: true,
    domainType: 'SIUnit',
    domainTypeAttributes: { symbol: 'bbl/d' },
  },
  condensate_bbl_per_day: {
    field: 'condensate_bbl_per_day',
    label: 'Condensate',
    sourceType: 'float',
    isShownByDefault: true,
    domainType: 'SIUnit',
    domainTypeAttributes: { symbol: 'bbl/d' },
  },
};

export const wellsByBOEProduction: Record<string, PostgresMappingItem> = {
  wellbore_uwi: {
    field: 'wellbore_uwi',
    label: 'UWI',
    isShownByDefault: true,
    width: 175,
    sourceType: 'int',
  },
  licensee: {
    field: 'licensee',
    label: 'Licensee',
    width: 175,
    isShownByDefault: true,
    sourceType: 'text',
  },
  total_production_boe_per_day: {
    field: 'total_production_boe_per_day',
    label: 'Production',
    domainType: 'SIUnit',
    domainTypeAttributes: { symbol: 'boe/d' },
    isShownByDefault: true,
    sourceType: 'float',
  },
  gas_mcf_per_day: {
    field: 'gas_mcf_per_day',
    label: 'Gas',
    domainType: 'SIUnit',
    domainTypeAttributes: { symbol: 'mcf/d' },
    isShownByDefault: true,
    sourceType: 'float',
  },
  oil_bbl_per_day: {
    field: 'oil_bbl_per_day',
    label: 'Oil',
    sourceType: 'float',
    isShownByDefault: true,
    domainType: 'SIUnit',
    domainTypeAttributes: { symbol: 'bbl/d' },
  },
  condensate_bbl_per_day: {
    field: 'condensate_bbl_per_day',
    label: 'Condensate',
    sourceType: 'float',
    isShownByDefault: true,
    domainType: 'SIUnit',
    domainTypeAttributes: { symbol: 'bbl/d' },
  },
  total_production_boe: {
    field: 'total_production_boe',
    label: 'Production',
    domainType: 'SIUnit',
    domainTypeAttributes: { symbol: 'boe' },
    isShownByDefault: true,
    sourceType: 'float',
  },
};

export const wellsByGasProduction: Record<string, PostgresMappingItem> = {
  wellbore_uwi: {
    field: 'wellbore_uwi',
    label: 'UWI',
    isShownByDefault: true,
    width: 175,
    sourceType: 'int',
  },
  licensee: {
    field: 'licensee',
    label: 'Licensee',
    width: 175,
    isShownByDefault: true,
    sourceType: 'text',
  },
  gas_mcf_per_day: {
    field: 'gas_mcf_per_day',
    label: 'Gas',
    domainType: 'SIUnit',
    domainTypeAttributes: { symbol: 'mcf/d' },
    isShownByDefault: true,
    sourceType: 'float',
  },
  oil_bbl_per_day: {
    field: 'oil_bbl_per_day',
    label: 'Oil',
    sourceType: 'float',
    isShownByDefault: true,
    domainType: 'SIUnit',
    domainTypeAttributes: { symbol: 'bbl/d' },
  },
  condensate_bbl_per_day: {
    field: 'condensate_bbl_per_day',
    label: 'Condensate',
    sourceType: 'float',
    isShownByDefault: true,
    domainType: 'SIUnit',
    domainTypeAttributes: { symbol: 'bbl/d' },
  },
};
