import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { isNil } from 'lodash-es';

const minZoomLevels: Record<WorkspaceItem['id'], number> = {
  wells: 9,
  wells_usa: 10,
  pipelines: 9,
  facilities: 9,
  mineral_rights: 6,
  open_mineral_rights: 6,
  land_postings: 6,
  units: 6,
  pools: 6,
  dispositions: 6,
  grids: 9,
};

export function getMinZoomLevel(
  itemId: WorkspaceItem['id'] | undefined
): number {
  if (isNil(itemId)) return 0;
  return minZoomLevels[itemId] ?? 0;
}
