import { Alert, Box, Typography } from '@mui/material';
import { GridOverlay } from '@mui/x-data-grid-pro';
import { areFiltersApplied } from '@pn/core/domain/query';
import { useWorkspaceStorage } from '@pn/core/storage';
import { isNil } from 'lodash-es';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  gridOverlay: {
    background: theme.palette.background.paper,
  },
  icon: {
    fontSize: 48,
  },
  link: {
    cursor: 'pointer',
  },
}));

type Props = {
  overlayText: string;
  icon: React.ComponentType<any>;
};

export function CustomNoRowsOverlay({ overlayText, icon: Icon }: Props) {
  const { classes } = useStyles();

  const { workspaceItemSelected } = useWorkspaceStorage();

  const noFilterResults = areFiltersApplied(
    workspaceItemSelected?.query.filters ?? []
  );
  const noSqlResults = !isNil(workspaceItemSelected?.query.sql);

  return (
    <GridOverlay className={classes.gridOverlay}>
      <Box textAlign="center">
        {noFilterResults ? (
          <Alert severity="warning">
            No results found from the filters applied
          </Alert>
        ) : noSqlResults ? (
          <Alert severity="warning">
            No results found from the SQL query applied
          </Alert>
        ) : (
          <Box>
            <Icon color="disabled" className={classes.icon} />
            <Typography color="textSecondary">{overlayText}</Typography>
          </Box>
        )}
      </Box>
    </GridOverlay>
  );
}
