import {
  toMappingItem,
  type Mapping,
  type MappingItem,
} from '@pn/core/domain/data';
import { isDateString, isWKTString } from '@pn/core/domain/types';
import {
  cloneDeep,
  isArray,
  isBoolean,
  isNil,
  isNumber,
  isString,
  take,
} from 'lodash-es';
import { GeoJsonDataItem } from './types';

const MAX_ITEMS_TO_ANALYZE = 1e3;

const PRESET_MAPPING_ITEMS: Record<string, MappingItem> = {
  internal_id: {
    source: 'geojson',
    field: 'internal_id',
    domainType: 'string',
    ui: {
      label: 'Petro Ninja ID',
      folder: 'Properties',
      isShownByDefault: true,
    },
    sourceType: '', // TODO remove when we get rid of ElasticSearch
  },
  geometry: {
    source: 'geojson',
    field: 'geometry',
    domainType: 'GeoShape',
    ui: {
      label: 'N/A',
      folder: 'N/A',
      isNotRenderable: true,
    },
    sourceType: '', // TODO remove when we get rid of ElasticSearch
  },
};

export function generateMappingFromGeoJsonData(
  geoJsonData: GeoJsonDataItem[]
): Mapping {
  const mapping: Mapping = cloneDeep(PRESET_MAPPING_ITEMS);

  const geoJsonDataSnapshot = take(geoJsonData, MAX_ITEMS_TO_ANALYZE);

  getAllKeys(geoJsonDataSnapshot).forEach((key) => {
    if (!isNil(PRESET_MAPPING_ITEMS[key])) return;

    const mappingItem = generateMappingItem(geoJsonDataSnapshot, key);
    if (!isNil(mappingItem)) mapping[key] = mappingItem;
  });

  return mapping;
}

function getAllKeys(geoJsonData: GeoJsonDataItem[]): string[] {
  const keys: string[] = [];

  geoJsonData.forEach((item) => {
    Object.keys(item).forEach((key) => {
      if (!keys.includes(key)) keys.push(key);
    });
  });

  return keys;
}

function generateMappingItem(
  geoJsonData: GeoJsonDataItem[],
  key: string
): MappingItem | undefined {
  const result = iterateToDeduceDomainType(geoJsonData, key);
  if (isNil(result)) return undefined;

  return toMappingItem({
    ...result,
    source: 'geojson',
    field: key,
    ui: {
      label: key,
      folder: 'Properties',
      isShownByDefault: true,
    },
    sourceType: '', // TODO remove when we get rid of ElasticSearch
  });
}

type DeducedResult =
  | {
      domainType: 'DateString';
      domainTypeAttributes: { format: string };
    }
  | { domainType: 'WKT' | 'boolean' | 'number' | 'string' };

function iterateToDeduceDomainType(
  geoJsonData: GeoJsonDataItem[],
  key: string
): DeducedResult | undefined {
  for (const geoJsonDataItem of geoJsonData) {
    const result = deduceDomainType(geoJsonDataItem[key]);
    if (!isNil(result)) return result;
  }

  return undefined;
}

function deduceDomainType(value: unknown): DeducedResult | undefined {
  if (isArray(value))
    throw new Error(`Arrays are not supported. Value: ${value}`);
  if (isDateString(value))
    return {
      domainType: 'DateString',
      domainTypeAttributes: { format: 'yyyy-MM-dd' },
    };
  if (isWKTString(value)) return { domainType: 'WKT' };
  if (isBoolean(value)) return { domainType: 'boolean' };
  if (isNumber(value)) return { domainType: 'number' };
  if (isString(value)) return { domainType: 'string' };
  if (isNil(value)) return undefined;

  throw new Error(`Cannot deduce domain type for value ${value}`); // should never happen
}
