import type { PostgresMappingItem } from '@pn/services/api/data/types';

const wellLicenceFields: Record<string, PostgresMappingItem> = {
  id: {
    field: 'id',
    label: 'Licence Number',
    width: 80,
    sourceType: 'int',
    isShownByDefault: true,
  },
  licensee: {
    field: 'licensee',
    label: 'Licensee',
    isShownByDefault: true,
    sourceType: 'text',
  },
  confidential_release_date: {
    field: 'confidential_release_date',
    label: 'Release Date',
    isShownByDefault: true,
    sourceType: 'text',
    width: 98,
  },
  oil_production_bbl_per_day: {
    field: 'oil_production_bbl_per_day',
    label: 'Oil',
    sourceType: 'float',
    isShownByDefault: true,
    domainType: 'SIUnit',
    domainTypeAttributes: { symbol: 'bbl/d' },
    width: 80,
  },
  c5_cond_production_bbl_per_day: {
    field: 'c5_cond_production_bbl_per_day',
    label: 'Condensate',
    sourceType: 'float',
    isShownByDefault: true,
    domainType: 'SIUnit',
    domainTypeAttributes: { symbol: 'bbl/d' },
    width: 80,
  },
  gas_production_mcf_per_day: {
    field: 'gas_production_mcf_per_day',
    label: 'Gas',
    sourceType: 'float',
    isShownByDefault: true,
    domainType: 'SIUnit',
    domainTypeAttributes: { symbol: 'mcf/d' },
    width: 80,
  },
  c2c3c4_production_bbl_per_day: {
    field: 'c2c3c4_production_bbl_per_day',
    label: 'NGL',
    sourceType: 'float',
    isShownByDefault: true,
    domainType: 'SIUnit',
    domainTypeAttributes: { symbol: 'bbl/d' },
    width: 80,
  },
  formations: {
    field: 'formations',
    label: 'Formations',
    isShownByDefault: true,
    sourceType: 'text',
  },
  fields: {
    field: 'fields',
    label: 'Field',
    isShownByDefault: true,
    sourceType: 'text',
  },
  production_hours: {
    field: 'production_hours',
    label: 'Producing Hours',
    isShownByDefault: true,
    sourceType: 'text',
  },
  on_prod_date: {
    field: 'on_prod_date',
    label: 'On Prod',
    sourceType: 'date',
    isShownByDefault: true,
    width: 100,
  },
  cumulative_oil_volume_bbl: {
    field: 'cumulative_oil_volume_bbl',
    label: 'Oil Cumulative',
    sourceType: 'float',
    isShownByDefault: true,
    domainType: 'SIUnit',
    domainTypeAttributes: { symbol: 'bbl' },
  },
};

export const confidentialWellsLicensesMapping: Record<
  string,
  PostgresMappingItem
> = {
  ...wellLicenceFields,
};

export const declassifiedWellsLicensesMapping: Record<
  string,
  PostgresMappingItem
> = {
  ...wellLicenceFields,
};
