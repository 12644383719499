import type { PostgresMappingItem } from '@pn/services/api/data/types';

export const mineralRightsTransfersMapping: Record<
  string,
  PostgresMappingItem
> = {
  id: {
    field: 'id',
    label: 'ID',
    width: 175,
    sourceType: 'int',
  },
  created_at: {
    field: 'created_at',
    domainType: 'DateString',
    label: 'Transfer Date',
    isShownByDefault: true,
    width: 125,
    sourceType: 'date',
  },
  agreement_count: {
    field: 'agreement_count',
    label: 'Agreements',
    isShownByDefault: true,
    sourceType: 'int',
    width: 80,
  },
  seller_company: {
    field: 'seller_company',
    label: 'From',
    isShownByDefault: true,
    sourceType: 'text',
  },
  buyer_company: {
    field: 'buyer_company',
    label: 'To',
    isShownByDefault: true,
    sourceType: 'text',
  },
  province: {
    field: 'province',
    label: 'Province',
    isShownByDefault: true,
    sourceType: 'text',
    width: 60,
  },
  net_area_change_ha: {
    field: 'net_area_change_ha',
    label: 'Net Area Change',
    domainType: 'SIUnit',
    domainTypeAttributes: { symbol: 'ha' },
    isShownByDefault: true,
    width: 100,
    sourceType: 'float',
  },
  petroninja_land_id_list: {
    field: 'petroninja_land_id_list',
    label: 'Land ID List',
    sourceType: 'json',
    isNotRenderable: true,
  },
  agreement_id_list: {
    field: 'agreement_id_list',
    label: 'Agreement Id List',
    sourceType: 'json',
    isNotRenderable: true,
  },
};
