import type { PostgresMappingItem } from '@pn/services/api/data/types';

export const aerTransfersMapping: Record<string, PostgresMappingItem> = {
  id: {
    field: 'id',
    label: 'ID',
    width: 175,
    sourceType: 'int',
  },
  last_status_date: {
    field: 'last_status_date',
    domainType: 'DateString',
    label: 'Date',
    isShownByDefault: true,
    width: 125,
    sourceType: 'date',
  },
  from_company: {
    field: 'from_company',
    label: 'From',
    isShownByDefault: true,
    sourceType: 'text',
  },
  to_company: {
    field: 'to_company',
    label: 'To',
    isShownByDefault: true,
    sourceType: 'text',
  },
  application_ids: {
    field: 'application_ids',
    label: 'Application IDs',
    isShownByDefault: true,
    isNotRenderable: true,
    sourceType: 'json',
  },
  status: {
    field: 'status',
    label: 'Status',
    isShownByDefault: true,
    sourceType: 'text',
    domainTypeAttributes: {
      options: ['Approved', 'Closed', 'Withdrawn'],
    },
  },
  province: {
    field: 'province',
    label: 'Province',
    sourceType: 'text',
    width: 60,
  },
  wells_count: {
    field: 'wells_count',
    label: 'Wells',
    isShownByDefault: true,
    sourceType: 'int',
    domainType: 'number',
  },
  pipelines_count: {
    field: 'pipelines_count',
    label: 'Pipelines',
    isShownByDefault: true,
    sourceType: 'int',
    domainType: 'number',
  },
  facilities_count: {
    field: 'facilities_count',
    label: 'Facilities',
    isShownByDefault: true,
    sourceType: 'int',
    domainType: 'number',
  },
  grid_ids: {
    field: 'grid_ids',
    label: 'Grid IDs',
    isShownByDefault: true,
    isNotRenderable: true,
    width: 75,
    sourceType: 'json',
  },
};
