import { cachePolicy } from '@pn/core/cachePolicy';
import {
  formatDataType,
  type DataItem,
  type Mapping,
} from '@pn/core/domain/data';
import { getScrollableQuery } from '@pn/core/domain/query';
import type { WorkspaceItem } from '@pn/core/domain/workspace';
import { handleError } from '@pn/core/errors/handleError';
import { dynamicDataProvider } from '@pn/core/providers/data/dynamicDataProvider';
import { isCacheStale } from '@pn/core/utils/cache';
import { log } from '@pn/core/utils/debug';
import { hashSortedObject } from '@pn/services/utils/hash';

export type TableData = {
  data: DataItem[];
  totalCount: number;
  timestamp: number;
};

const tableDataCache = new Map<number, TableData>();

export async function getTableData(params: {
  item: WorkspaceItem;
  mapping: Mapping;
  pageSize: number;
}): Promise<TableData> {
  const { item, mapping, pageSize } = params;

  if (item.dataSource.type === 'none') {
    return {
      data: [],
      totalCount: 0,
      timestamp: 0,
    };
  }

  const tableQuery = getScrollableQuery(item.query, pageSize);

  const hash = hashSortedObject(tableQuery);
  if (cachePolicy.tableData && tableDataCache.has(hash)) {
    const cacheCandidate = tableDataCache.get(hash)!;
    if (isCacheStale(cacheCandidate.timestamp)) {
      log.info('%ctableDataCache invalidated', 'color: #009688', hash);
      tableDataCache.delete(hash);
    } else {
      log.info('%ctableDataCache hit', 'color: #009688', hash);
      return cacheCandidate;
    }
  }

  const tableData: TableData = {
    data: [],
    totalCount: 0,
    timestamp: 0,
  };

  try {
    log.info('%cfetching tableData from data source', 'color: #009688');
    const { data, totalCount } = await dynamicDataProvider(
      item.dataSource
    ).getDataByQuery({
      query: tableQuery,
      mapping,
    });

    Object.assign(tableData, {
      data,
      totalCount,
    });
  } catch (error) {
    handleError({
      error,
      userFriendlyMessage: `Failed to get ${formatDataType(
        item.dataType
      )} table data`,
    });
  }

  if (cachePolicy.tableData) {
    tableData.timestamp = Date.now();
    tableDataCache.set(hash, tableData);
  }

  return tableData;
}
