import { isNil, uniq } from 'lodash-es';
import type { Mapping } from './mappingItem';

export type ColumnsTreeFolder = {
  id: string;
  folder: string;
  children: ColumnsTreeItem[];
};
export type ColumnsTreeColumn = {
  id: string;
  field: string;
  label: string;
  tooltip?: string;
};

export type ColumnsTreeItem = ColumnsTreeFolder | ColumnsTreeColumn;
export type ColumnsTree = ColumnsTreeFolder;

export const getColumnsTreeFromMapping = (mapping: Mapping): ColumnsTree => {
  const renderableMapping = Object.values(mapping).filter(
    (m): m is NonNullable<typeof m> => !m?.ui.isNotRenderable
  );

  const tree: ColumnsTreeFolder = {
    id: '_root',
    folder: '_root',
    children: [],
  };

  /* Mapping items on the root level */
  const rootLevelMappingItems = renderableMapping.filter(
    (m) => isNil(m.ui.folder) && isNil(m.ui.subFolder)
  );

  tree.children.push(
    ...rootLevelMappingItems.map((m) => ({
      id: m.field,
      field: m.field,
      label: m.ui.label,
      tooltip: m.ui.tooltip,
    }))
  );

  /* Mapping items in folders */
  const folderLevelMappingItems = renderableMapping.filter(
    (m) => !isNil(m.ui.folder) && isNil(m.ui.subFolder)
  );

  const folders = uniq(folderLevelMappingItems.map(({ ui }) => ui.folder!));

  folders.forEach((folder) => {
    const folderMappingItems = folderLevelMappingItems.filter(
      (m) => m.ui.folder === folder
    );

    const folderTree: ColumnsTreeFolder = {
      id: folder,
      folder,
      children: [],
    };

    folderTree.children.push(
      ...folderMappingItems.map((m) => ({
        id: m.field,
        field: m.field,
        label: m.ui.label,
        tooltip: m.ui.tooltip,
      }))
    );

    tree.children.push(folderTree);
    tree.children.sort(compareColumnsTreeItems);

    /* Mapping items in sub-folders */
    const subFolderLevelMappingItems = renderableMapping.filter(
      (m) => m.ui.folder === folder && !isNil(m.ui.subFolder)
    );

    const subFolders = uniq(
      subFolderLevelMappingItems.map(({ ui }) => ui.subFolder!)
    );

    subFolders.forEach((subFolder) => {
      const subFolderMappingItems = subFolderLevelMappingItems.filter(
        (m) => m.ui.subFolder === subFolder
      );

      const subFolderTree: ColumnsTreeFolder = {
        id: subFolder,
        folder: subFolder,
        children: [],
      };

      subFolderTree.children.push(
        ...subFolderMappingItems.map((m) => ({
          id: m.field,
          field: m.field,
          label: m.ui.label,
          tooltip: m.ui.tooltip,
        }))
      );

      folderTree.children.push(subFolderTree);
      folderTree.children.sort(compareColumnsTreeItems);
    });
  });

  return tree;
};

/**
 * Places folder items before column items.
 */
function compareColumnsTreeItems(
  a: ColumnsTreeItem,
  b: ColumnsTreeItem
): 1 | 0 | -1 {
  if ('folder' in a && !('folder' in b)) {
    return -1;
  }
  if ('folder' in b && !('folder' in a)) {
    return 1;
  }

  return 0;
}

/**
 * Fields correspond to visible/exportable columns.
 */
export const getDefaultFieldsFromMapping = (mapping: Mapping): string[] => {
  return Object.entries(mapping).reduce<string[]>(
    (acc, [field, mappingItem]) => {
      if (
        !mappingItem?.ui.isNotRenderable &&
        mappingItem?.ui.isShownByDefault
      ) {
        acc.push(field);
      }
      return acc;
    },
    []
  );
};
