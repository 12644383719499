import type { PostgresMappingItem } from '@pn/services/api/data/types';

export const importantLandMappings: Record<string, PostgresMappingItem> = {
  id: {
    field: 'id',
    label: 'ID',
    width: 175,
    sourceType: 'int',
  },
  notice_date: {
    field: 'notice_date',
    domainType: 'DateString',
    label: 'Date',
    isShownByDefault: true,
    width: 125,
    sourceType: 'date',
  },
  contract_no: {
    field: 'contract_no',
    label: 'Parcel No.',
    sourceType: 'text',
    isShownByDefault: true,
    width: 80,
  },
  area_ha: {
    field: 'area_ha',
    label: 'Area',
    domainType: 'SIUnit',
    domainTypeAttributes: { symbol: 'ha' },
    isShownByDefault: true,
    width: 100,
    sourceType: 'float',
  },
};

export const secondaryLandMappings: Record<string, PostgresMappingItem> = {
  zones_json: {
    field: 'zones_json',
    label: 'Zones',
    sourceType: 'json',
    isShownByDefault: true,
    width: 200,
  },
  segments: {
    field: 'segments',
    label: '# of Segments',
    sourceType: 'text',
    width: 50,
  },
  contract_type: {
    field: 'contract_type',
    label: 'Contract Type',
    isShownByDefault: true,
    sourceType: 'text',
    domainTypeAttributes: { options: ['Lease', 'License'] },
  },
  province: {
    field: 'province',
    label: 'Province',
    sourceType: 'text',
    isShownByDefault: true,
    width: 60,
  },
  region_name: {
    field: 'region_name',
    label: 'Region',
    isShownByDefault: true,
    sourceType: 'text',
  },
  restriction_code_list: {
    field: 'restriction_code_list',
    label: 'Restrictions',
    sourceType: 'json',
    isNotRenderable: true,
  },
  lands_json: {
    field: 'lands_json',
    label: 'Lands JSON',
    sourceType: 'text',
  },
  is_partial_lsd_or_portion: {
    field: 'is_partial_lsd_or_portion',
    label: 'Is Partial',
    width: 60,
    isShownByDefault: true,
    sourceType: 'bool',
  },
  has_amendment: {
    field: 'has_amendment',
    label: 'Has Amendment',
    width: 60,
    isShownByDefault: true,
    sourceType: 'bool',
    domainType: 'boolean',
  },
  rights_type: {
    field: 'rights_type',
    domainType: 'string',
    label: 'Type',
    isShownByDefault: true,
    width: 125,
    sourceType: 'text',
  },
};

export const landPostingsMapping: Record<string, PostgresMappingItem> = {
  ...importantLandMappings,
  ...secondaryLandMappings,
};
