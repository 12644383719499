import { isString } from 'lodash-es';

export const pnDataTypes = [
  'wells',
  'wells_usa',
  'pipelines',
  'facilities',
  'mineral_rights',
  'open_mineral_rights',
  'land_postings',
  'units',
  'pools',
  'dispositions',
  'dispositions_historical',
  'grids',
  '62e083ef-8bf1-4371-8878-08d8f8c52228|wells', // TEMP
] as const;

export type PNDataType = (typeof pnDataTypes)[number];

export function isPNDataType(arg: unknown): arg is PNDataType {
  return isString(arg) && pnDataTypes.includes(arg as PNDataType);
}
