import type { PostgresMappingItem } from '@pn/services/api/data/types';
import { importantLandMappings, secondaryLandMappings } from './land-postings';

// NOTE: land results is just a superset of land postings
export const landResultsMapping: Record<string, PostgresMappingItem> = {
  ...importantLandMappings,
  result_bonus_cad: {
    field: 'result_bonus_cad',
    label: 'Result Bonus',
    isShownByDefault: true,
    sourceType: 'float',
    domainType: 'SIUnit',
    domainTypeAttributes: { symbol: '$' }, // should not be converted
  },
  result_status: {
    field: 'result_status',
    label: 'Status',
    isShownByDefault: true,
    sourceType: 'text',
    domainTypeAttributes: {
      options: ['Accepted', 'No Offers', 'Widthdrawn via Correction'],
    },
    width: 100,
  },
  owners: {
    field: 'owners',
    label: 'Owner',
    isShownByDefault: true,
    sourceType: 'json',
  },
  ...secondaryLandMappings,
};
